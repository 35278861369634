import authService from '../../../../services/auth'

export default {
    data() {
        return {
            password: {
                old_password: null,
                new_password: null,
                confirm_password: null
            },
        }
    },
    methods: {
        forceRerender () {
            this.$refs.modal.show()
        },
        async changePassword () {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                authService.changePassword(this.password).then(resp => {
                    if (!resp.error) {
                        this.$refs.modal.hide()
                        this._showToast('Password changed!')
                    }
                })
            }
        },
    }
}